import customerService from '@/services/customer';

import moment from 'moment';
const _ = require('lodash');

export default {
  components: {},
  name: 'CustomerList',
  data() {
    return {
      moment: moment,
      fetchingData: false,

      searchTerm: '',
      pageOptions: {
        pageSize: 50,
        pageNumber: 1,
        total: null
      },

      customerList: [],
      onlyRegistered: true
    };
  },

  methods: {
    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch(true);
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.page = 1;
      this.fetchCustomerList(true);
    },

    onPageChange() {
      this.fetchCustomerList(false);
    },

    async fetchCustomerList(getCount) {
      this.fetchingData = true;
      try {
        let params = {
          onlyRegistered: this.onlyRegistered,
          pageOptions: this.pageOptions,
          searchTerm: this.searchTerm,
          getCount: getCount
        };

        let result = await customerService.getListV2(params);
        this.customerList = result.data.list;

        if (getCount) this.pageOptions.total = result.data.count;

        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to get customer list! Please contact support.');
      }
    },

    getCustomerListLink(customerId) {
      return '/customer/detail?id=' + customerId;
    }
  },

  mounted() {
    this.fetchCustomerList(true);
  }
};
